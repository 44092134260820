<template>
  <b-card :title="$t('Dates')">

    <b-row>
      <b-col cols="12">
        <field-date
            :model.sync="startLocal"
            name="start"
            :is-required="true"
        />
      </b-col>
      <b-col cols="12">
        <field-date
            :model.sync="endLocal"
            name="end"
            :min-date="startLocal"
            :is-required="true"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import moment from 'moment'
import FieldDate from '../../../components/input/Date'

export default {
  components: {
    FieldDate
  },
  props: {
    start: {},
    end: {}
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startLocal = ref(props.start)
    const endLocal = ref(props.end)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(startLocal, val => {
      emit('update:start', moment(val).format('YYYY-MM-DD'))
    })
    watch(endLocal, val => {
      emit('update:end', moment(val).format('YYYY-MM-DD'))
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      startLocal,
      endLocal,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>