<template>
  <search-filter
      :items="items"
      :isActionColumnEnabled="true"
      itemView="list"
      :sortOptions="sortOptions"
      :columns="columns"
      :show-past-years-filter="true"
      :readOnly="true"
      @columnClick="false"
  >
    <!--  =========================== -->
    <!--  ===    Results infos    === -->
    <!--  =========================== -->
    <template #resultInfos="{items}">
      ({{ items.length }})
      <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
    </template>

    <!--  =========================== -->
    <!--  ===      Grid view      === -->
    <!--  =========================== -->

    <!--  =========================== -->
    <!--  ===      List view      === -->
    <!--  =========================== -->
    <!--      Type-->
    <template #listView_cell_type="{item}">
      {{ capitalize($tc(item.type)) }}
    </template>

    <!--      Actions-->
    <template #listView_cell_actions="{item}">
      <div class="text-nowrap">

        <!--          View-->
        <router-link
            :to="$router.resolve({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: item.entity.id } }).href"
            target="_blank"
            class="text-decoration-none"
        >
          <icon
              v-b-tooltip.hover.left="$t('View')"
              icon="external-link-alt"
              class="cursor-pointer"/>
        </router-link>

        <!--          Download-->
        <icon
            v-if="item.type == 'invoice'"
            @click.native.stop="downloadOutgoingInvoice(item.entity)"
            v-b-tooltip.hover.left="capitalize($t('Download'))"
            icon="download"
            class="cursor-pointe mx-1"/>
      </div>
    </template>


  </search-filter>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { useOutgoingInvoices } from '../../views/sales/outgoingInvoices/useOutgoingInvoices'

import SearchFilter from '../searchFilterV2/SearchFilter'
import i18n from '@/libs/i18n'
import useAPI from '../../utils/useAPI'
import moment from 'moment'

export default {
  components: {
    SearchFilter
  },
  props: {
    date: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([])
    const columns = ref([
      {
        display: capitalize(i18n.t('soldBy')),
        key: 'soldBy',
        sortable: true
      },
      {
        display: capitalize(i18n.t('type')),
        key: 'type',
        sortable: true
      },
      {
        display: capitalize(i18n.t('customer')),
        key: 'customer',
        sortable: true
      },
      {
        display: capitalize(i18n.t('customer')),
        key: 'customer',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('date')),
        key: 'date',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { awaitingExportSalesIndicator } = useAPI()

    const items = computed(() => {
      let output = []

      for (const month in awaitingExportSalesIndicator.value.turnoverByMonths) {
        if (moment(month) <= moment(props.date)) {
          awaitingExportSalesIndicator.value.turnoverByMonths[month].detail.outgoingInvoiceEntities.forEach(o => {

            output.push({
              type: 'invoice',
              customer: o.customerCompany != null ? o.customerCompany : o.customerIndividual,
              title: o.title,
              date: o.billingDate,
              preTaxAmount: o.preTaxAmount,
              totalAmount: o.totalAmount,
              soldBy: o.logs[0].by._display,
              entity: o
            })
          })
        }
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useOutgoingInvoices()

    const getPreTaxAmounts = (concernedItems) => {
      let preTaxAmounts = 0

      concernedItems.forEach(item => {
        if (item.type == 'offer') {
          if (item.entity._isValidated) {
            item.entity.products.forEach(p => {
              if (parseFloat(p._remaining) > 0) {
                preTaxAmounts += parseFloat(p._remaining) * parseFloat(p.preTaxAmount)
              }

              if (p.childs.length) {
                p.childs.forEach(invoicedProduct => {
                  preTaxAmounts += parseFloat(invoicedProduct.preTaxAmount)
                })
              }
            })

          } else {
            preTaxAmounts += parseFloat(item.preTaxAmount)
          }
        } else if (item.type == 'invoice') {
          if (item.entity.products.length == 0) {
            preTaxAmounts += parseFloat(item.entity.preTaxAmount)
          } else {
            item.entity.products.forEach(p => {
              if (p.parent == null) {
                preTaxAmounts += parseFloat(p.preTaxAmount)
              }
            })
          }
        }
      })

      return preTaxAmounts
    }

    const getTotalAmounts = (concernedItems) => {
      let totalAmounts = 0

      concernedItems.forEach(item => {
        if (item.type == 'offer') {
          if (item.entity._isValidated) {
            item.entity.products.forEach(p => {
              if (parseFloat(p._remaining) > 0) {
                totalAmounts += parseFloat(p._remaining) * parseFloat(p.totalAmount)
              }

              if (p.childs.length) {
                p.childs.forEach(invoicedProduct => {
                  totalAmounts += parseFloat(invoicedProduct.totalAmount)
                })
              }
            })

          } else {
            totalAmounts += parseFloat(item.totalAmount)
          }
        } else if (item.type == 'invoice') {
          if (item.entity.products.length == 0) {
            totalAmounts += parseFloat(item.entity.totalAmount)
          } else {
            item.entity.products.forEach(p => {
              if (p.parent == null) {
                totalAmounts += parseFloat(p.totalAmount)
              }
            })
          }
        }
      })

      return totalAmounts
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      sortOptions,
      columns,

      // Computed
      items,

      // Methods
      downloadOutgoingInvoice,
      getPreTaxAmounts,
      getTotalAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>