import { ref, computed, watch } from '@vue/composition-api'
import { useSearchFilter } from './useSearchFilter'
import { capitalize, currency } from '../../utils/filter'
import { isObject } from '../../utils/utils'

import moment from 'moment'
import useAPI from '../../utils/useAPI'
import i18n from '../../libs/i18n'
import FieldDate from '../input/Date'

export default {
  components: {
    FieldDate
  },
  // props: {},
  setup (props) {
    const {
      query,
      pageNumber,
      perPage,
      itemViewOptions,
      localFilters,
      selectedFilters,
      selectedView,
      mapView,
      paginateArray,
      selectFilter,
      getLast3months,
      getLasYears,
      isLikeQuery,
      toogleItem,
      rowClass,
      tableSortCompare,
      sortBy,
      sortByIsDesc,
    } = useSearchFilter()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { isLoading } = useAPI()

    const itemPerPagesDisplayed = computed(() => {
      return (pageNumber.value * perPage.value - (perPage.value - 1)).toString() + ' - ' +
        (paginatedItems.value.length < perPage.value ? localItems.value.length : (pageNumber.value * paginatedItems.value.length)).toString() +
        ' ' + i18n.t('on') + ' ' + localItems.value.length.toString()
    })

    const paginatedItems = computed(() => {
      return paginateArray(localItems.value, perPage.value, pageNumber.value)
    })

    const localItems = computed(() => {
      let output = props.items

      if (selectedFilters.value.length) {
        // // OR
        // output = props.items.filter(item => {
        //   return selectedFilters.value.some(filter => {
        //     if (filter.key == '_date_month_filter') {
        //       return moment(item[filter.column.key]).format('YYYY-MM') == filter.value
        //     } else if (filter.key == '_date_year_filter') {
        //       return moment(item[filter.column.key]).format('YYYY') == filter.value
        //     } else if (filter.key == '_date_custom_filter') {
        //       return moment(filter.value.start) <= moment(item[filter.column.key]) && moment(item[filter.column.key]) <= moment(filter.value.end)
        //     } else {
        //       return item._filters.some(flt => flt.key == filter.key && flt.value == true)
        //     }
        //   })
        // })

        // AND
        selectedFilters.value.forEach(filter => {
          output = output.filter(item => {
            if (filter.key == '_date_month_filter') {
              return moment(item[filter.column.key]).format('YYYY-MM') == filter.value
            } else if (filter.key == '_date_year_filter') {
              return moment(item[filter.column.key]).format('YYYY') == filter.value
            } else if (filter.key == '_date_custom_filter') {
              return moment(filter.value.start) <= moment(item[filter.column.key]) && moment(item[filter.column.key]) <= moment(filter.value.end)
            } else if ('_filters' in item) {
              return item._filters.some(flt => flt.key == filter.key && flt.value == true)
            } else {
              return false
            }
          })
        })

      }

      if (query.value != '') {
        output = output.filter(item => recursiveFilter(item))
      }

      if (sortBy.value != null) {
        output.sort((aRow, bRow) => {
          let a = (typeof aRow[sortBy.value] === 'undefined') ? '' : aRow[sortBy.value]
          let b = (typeof bRow[sortBy.value] === 'undefined') ? '' : bRow[sortBy.value]
          // console.log(sortBy.value, aRow)

          if (sortBy.value == 'contact') {
            a = ('_display' in aRow) ? aRow._display : a
            a = ('customerCompany' in aRow && aRow.customerCompany != null) ? aRow.customerCompany._display : a
            a = ('customerIndividual' in aRow && aRow.customerIndividual != null) ? aRow.customerIndividual._display : a
            a = ('supplierCompany' in aRow && aRow.supplierCompany != null) ? aRow.supplierCompany._display : a

            b = ('_display' in bRow) ? bRow._display : b
            b = ('customerCompany' in bRow && bRow.customerCompany != null) ? bRow.customerCompany._display : b
            b = ('customerIndividual' in bRow && bRow.customerIndividual != null) ? bRow.customerIndividual._display : b
            b = ('supplierCompany' in bRow && bRow.supplierCompany != null) ? bRow.supplierCompany._display : b
          } else if (sortBy.value == 'employee') {
            a = aRow._display
            b = bRow._display
          } else if (sortBy.value == '_employeeSalary') {
            a = aRow.employee._display
            b = bRow.employee._display
          } else if (sortBy.value == 'lastModification') {
            a = moment(aRow.logs[aRow.logs.length - 1].at)
            b = moment(bRow.logs[bRow.logs.length - 1].at)
            if (sortByIsDesc.value) {
              return a > b ? -1 : a > b ? 1 : 0
            } else {
              return a < b ? -1 : a > b ? 1 : 0
            }
          } else if (sortBy.value == 'bank') {
            a = aRow.bankAccount._display
            b = bRow.bankAccount._display
          } else if (isObject(a) && ('_display' in a) && isObject(b) && ('_display' in b)) {
            a = a._display
            b = b._display
          } else if (!isNaN(a) || !isNaN(b)) {
            a = isNaN(a)?0:parseFloat(a)
            b = isNaN(b)?0:parseFloat(b)
          }

          if (
            (typeof a === 'number' && typeof b === 'number') ||
            (a instanceof Date && b instanceof Date)
          ) {
            if (sortByIsDesc.value) {
              return a > b ? -1 : a > b ? 1 : 0
            } else {
              return a < b ? -1 : a > b ? 1 : 0
            }
          } else if (moment(a).isValid() && moment(b).isValid()) {
            if (sortByIsDesc.value) {
              return moment(a) > moment(b) ? -1 : moment(a) > moment(b) ? 1 : 0
            } else {
              return moment(a) < moment(b) ? -1 : moment(a) > moment(b) ? 1 : 0
            }

          } else if (typeof a == 'string' && typeof b == 'string') {
            // console.log(a, ' VS ', b, a.localeCompare(b))
            if (sortByIsDesc.value) {
              return b.localeCompare(a)
            } else {
              return a.localeCompare(b)
            }
          } else {
            // Otherwise stringify the field data and use String.prototype.localeCompare
            return toString(a).localeCompare(toString(b))
          }
        })
      }

      if (selectedView.value == 'map' && mapView.value != null) {
        mapView.value.populateItems(output)
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.items, val => {
      if (props.showPastYearsFilter) {
        props.columns.filter(c => c.isDate).forEach(c => {
          let index = localFilters.value.findIndex(f => f.key == c.key)
          let lastYearsArray = getLasYears(c, val)

          lastYearsArray.forEach(lastYearsObject => {
            if (!localFilters.value[index].children.some(c => c.value == lastYearsObject.value)) {
              localFilters.value[index].children.push(lastYearsObject)
            }
          })
        })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateLocalFilters = () => {
      localFilters.value = []
      props.sortOptions.forEach(s => {
        localFilters.value = localFilters.value.concat(s)

        if (s.selected) {
          selectFilter(s)
        }
      })

      if (props.columns.some(c => c.isDate)) {
        localFilters.value.push({
          'divider': true
        })

        props.columns.filter(c => c.isDate).forEach(c => {
          let children = getLast3months(c)
          let start = moment().subtract(1, 'months').startOf('month')
          let end = moment().endOf('month')

          if (props.showPastYearsFilter) {
            children = children.concat([{ 'divider': true }])
          }

          children.push({
            text: 'CUSTOM',
            column: c,
            key: '_date_custom_filter',
            value: {
              start: start.format('YYYY-MM-DD'),
              end: end.format('YYYY-MM-DD')
            }
          })
          children = children.concat([{ 'divider': true }])

          localFilters.value.push({
            text: c.display,
            key: c.key,
            showSubMenu: false,
            children: children
          })
        })
      }

      // console.log(localFilters.value)
    }

    const recursiveFilter = (item) => {
      return Object.values(item).some((el, index) => {
        // console.log(Object.keys(item)[index])
        // console.log(el)

        let isKeyContact = Object.keys(item)[index] == 'customerCompany' ||
          Object.keys(item)[index] == 'customerIndividual'

        if (props.columns.some(c => {
          return c.key == Object.keys(item)[index] ||
            (c.key == 'contact' && isKeyContact)
        })) {
          if (isObject(el)) {
            return recursiveFilter(el)
          } else {
            return isLikeQuery(el)
          }
        } else if (
          Object.keys(item)[index] == '_display'
        ) {
          return isLikeQuery(el)
        }
      })
    }

    const tableColumnsLocal = computed(() => {
      let output = props.columns

      if (props.isActionColumnEnabled) {
        output.push({ key: 'actions', display: i18n.tc('action', 2) })
      }

      return output
    })

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateLocalFilters()

    if (props.itemView != 'grid') {
      selectedView.value = props.itemView
    }
    if (props.addMapView) {
      itemViewOptions.value.push({
        icon: 'globe-americas', value: 'map'
      })
    }

    return {
      // Components
      capitalize,
      currency,
      isObject,

      // Data
      selectedView,
      query,
      pageNumber,
      perPage,
      itemViewOptions,
      localFilters,
      selectedFilters,
      mapView,

      // Computed
      isLoading,
      itemPerPagesDisplayed,
      localItems,
      paginatedItems,
      tableColumnsLocal,

      // Methods
      selectFilter,
      toogleItem,
      rowClass,
      tableSortCompare,
      populateLocalFilters

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
