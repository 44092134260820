<template>
  <b-modal
      v-if="elementsToDetail"
      :title="title"
      v-model="isOpenLocal"
      size="xl"
      scrollable
      hide-footer
  >
    <b-tabs>
      <b-tab :title="capitalize($t('incoming'))">
        <b-card-text>
          <sales-indicators
              :sales="elementsToDetail.sales"
          />
        </b-card-text>
      </b-tab>
      <b-tab :title="capitalize($t('outgoing'))">
        <b-card-text>
          <expenses-indicators
              :expenses="elementsToDetail.expenses"
          />
        </b-card-text>
      </b-tab>
      <b-tab :title="capitalize($t('awaitingExportOutgoing'))">
        <b-card-text>
          <awaiting-export-sales-indicators
              :date="date"/>
        </b-card-text>
      </b-tab>
      <b-tab :title="capitalize($t('awaitingExportIncoming'))">
        <b-card-text>
          <awaiting-export-expenses-indicators
              :date="date"/>
        </b-card-text>
      </b-tab>
      <b-tab :title="capitalize($tc('documents',2))">
        <b-card-text>

          <!--          Detail revenue by excel-->
          <!--          <document-content-->
          <!--              :document="{-->
          <!--            name: $t('DetailRevenue'),-->
          <!--            fileName: title,-->
          <!--            extension: 'xlsx'-->
          <!--            }"-->
          <!--              :isDeletable="false"-->
          <!--              @download="downloadDetailRevenue('excel')"-->
          <!--          />-->

          <!--          Liaison form xlsx-->
          <document-content
              :document="{
            name: $t('LiaisonForm') + ' (Excel)',
            fileName: title,
            extension: 'xlsx'
            }"
              :isDeletable="false"
              :isParentMethodPriority="true"
              @download="downloadLiaisonForm"
              @click="downloadLiaisonForm"
          />
          <!--          Liaison form PDF-->
          <document-content
              :document="{
            name: $t('LiaisonForm') + ' (PDF)',
            fileName: title,
            extension: 'pdf'
            }"
              :isDeletable="false"
              :isParentMethodPriority="true"
              @download="downloadLiaisonForm('pdf')"
              @click="downloadLiaisonForm('pdf')"
          />

          <!--          Detail expenses by pdf-->
          <document-content
              :document="{
            name: $t('PurchasesMerging'),
            fileName: capitalize($t('purchases:')) + ' ' + title,
            extension: 'pdf'
            }"
              :isDeletable="false"
              :isParentMethodPriority="true"
              @download="downloadDetailPurchases"
              @click="downloadDetailPurchases"
          />

          <!--          Detail sales by pdf-->
          <document-content
              :document="{
            name: $t('SalesMerging'),
            fileName: capitalize($t('sales:')) + ' ' + title,
            extension: 'pdf'
            }"
              :isDeletable="false"
              :isParentMethodPriority="true"
              @download="downloadDetailSales"
              @click="downloadDetailSales"
          />

        </b-card-text>
      </b-tab>

    </b-tabs>


  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import ExpensesIndicators from '../../indicatorDetails/ExpensesIndicators'
import AwaitingExportExpensesIndicators from '../../indicatorDetails/AwaitingExportExpensesIndicators'
import SalesIndicators from '../../indicatorDetails/SalesIndicators'
import AwaitingExportSalesIndicators from '../../indicatorDetails/AwaitingExportSalesIndicators'
import DocumentContent from '../../card/components/DocumentContent'
import store from '@/store'
import moment from 'moment'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    ExpensesIndicators,
    AwaitingExportExpensesIndicators,
    SalesIndicators,
    AwaitingExportSalesIndicators,
    DocumentContent
  },
  props: {
    date: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    elementsToDetail: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // console.log(props.awaitingExportElementsToDetail)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(props.isOpen)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { awaitingExportExpensesIndicator } = useAPI()

    const title = computed(() => {
      return moment(props.date + '-01').format('MMMM YYYY')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const downloadDetailPurchases = (type) => {
      if (type == 'excel') {
        store.dispatch('document/generateExcelDetailRevenue', props.date)
      } else {
        store.dispatch('document/generatePdfDetailPurchases', props.date)
      }
    }
    const downloadDetailSales = () => {
      store.dispatch('document/generatePdfDetailSales', props.date)
    }

    const downloadLiaisonForm = (type = 'xlsx') => {
      store.dispatch('document/generateLiaisonForm', { date: props.date, type: type })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,

      // Computed
      title,
      awaitingExportExpensesIndicator,

      // Methods
      downloadDetailPurchases,
      downloadDetailSales,
      downloadLiaisonForm,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.nav-tabs {
  margin-bottom: 0rem;
}
</style>