<template>

  <search-filter
      :items="items"
      :isActionColumnEnabled="true"
      itemView="list"
      :sortOptions="sortOptions"
      :columns="columns"
      :show-past-years-filter="true"
      :readOnly="true"
      @columnClick="false"
  >
    <!--  =========================== -->
    <!--  ===    Results infos    === -->
    <!--  =========================== -->
    <template #resultInfos="{items}">
      ({{ items.length }})
      <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
    </template>

    <!--  =========================== -->
    <!--  ===      Grid view      === -->
    <!--  =========================== -->

    <!--  =========================== -->
    <!--  ===      List view      === -->
    <!--  =========================== -->
    <!--      Type-->
    <template #listView_cell_type="{item}">
      <icon
          v-b-tooltip.hover.left="capitalize(item.type=='toll'?$tc('transport'):(item.type=='default'?$tc('generalExpenses'):$tc(item.type)))"
          :icon="getIncomingInvoiceIconType(item).icon"
          :library="getIncomingInvoiceIconType(item).library?getIncomingInvoiceIconType(item).library:'fas'"
      />
    </template>

    <!--      Supplier-->
    <template #listView_cell_supplier="{item}">
      <span v-if="item.supplier != null">{{ capitalize($tc(item.supplier._display)) }}</span>
    </template>

    <!--      Date-->
    <template #listView_cell_date="{item}">
      {{ item.date|moment('L') }}
    </template>

    <!--      Actions-->
    <template #listView_cell_actions="{item}">
      <div class="text-nowrap">
        <!--          Download-->
        <icon
            @click.native.stop="downloadItem(item)"
            v-b-tooltip.hover.left="capitalize($t('Download'))"
            icon="download"
            class="cursor-pointe mx-1"/>
      </div>
    </template>


  </search-filter>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { useIncomingInvoices } from '../../views/purchases/incomingInvoices/useIncomingInvoices'

import SearchFilter from '../searchFilterV2/SearchFilter'
import i18n from '@/libs/i18n'
import useAPI from '../../utils/useAPI'
import moment from 'moment'

export default {
  components: {
    SearchFilter
  },
  props: {
    date: {
      type: String,
      default: ''
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.t('generalExpenses')),
        key: '_filter_isGeneralExpenseType',
      },
      {
        text: capitalize(i18n.t('transport')),
        key: '_filter_isTollType',
      },
      {
        text: capitalize(i18n.t('accommodation')),
        key: '_filter_isAccommodationType',
      },
      {
        text: capitalize(i18n.t('catering')),
        key: '_filter_isCateringType',
      },
      {
        text: capitalize(i18n.t('production')),
        key: '_filter_isProductionType',
      },
    ])

    const columns = ref([
      {
        display: capitalize(i18n.t('type')),
        key: 'type',
        sortable: true
      },
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplier',
        sortable: true
      },
      {
        display: capitalize(i18n.t('date')),
        key: 'date',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { awaitingExportExpensesIndicator } = useAPI()

    const items = computed(() => {
      let output = []

      for (const month in awaitingExportExpensesIndicator.value.expensesByMonths) {

        if (moment(month) <= moment(props.date)) {
          awaitingExportExpensesIndicator.value.expensesByMonths[month].detail.incomingInvoiceEntities.forEach(i => {

            if (i.paymentMethod.id == 5) {
              output.push({
                type: 'expenseAccount',
                supplier: i.paidBy,
                date: i.billingDate,
                preTaxAmount: i.preTaxAmount,
                totalAmount: i.totalAmount,
                entity: i
              })
            } else {
              output.push({
                type: 'invoice',
                supplier: i.supplierCompany,
                date: i.billingDate,
                preTaxAmount: i.preTaxAmount,
                totalAmount: i.totalAmount,
                entity: i
              })
            }

          })
        }


      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadIncomingInvoice, getIncomingInvoiceIconType } = useIncomingInvoices()

    const getPreTaxAmounts = (concernedItems) => {
      let preTaxAmounts = 0

      concernedItems.forEach(item => {
        preTaxAmounts += parseFloat(item.preTaxAmount)
      })

      return preTaxAmounts
    }

    const getTotalAmounts = (concernedItems) => {
      let totalAmounts = 0

      concernedItems.forEach(item => {
        totalAmounts += parseFloat(item.totalAmount)
      })

      return totalAmounts
    }

    const downloadItem = (item) => {
      if (item.type == 'charge') {
        let link = document.createElement('a')
        link.href = item.entity.document.url
        link.target = '_blank'
        link.download = item.entity.document.name + '.' + item.entity.document.extension
        link.click()
      } else if (item.type == 'expenseAccount' || item.type == 'invoice') {
        if (item.entity.receipt != null) {
          let link = document.createElement('a')
          link.href = item.entity.receipt.url
          link.target = '_blank'
          link.download = item.entity.receipt.name + '.' + item.entity.receipt.extension
          link.click()
        }
      } else if (item.type == 'salary') {
        let link = document.createElement('a')
        link.href = item.entity.paySlip.url
        link.target = '_blank'
        link.download = item.entity.paySlip.name + '.' + item.entity.paySlip.extension
        link.click()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      // tableColumns,
      sortOptions,
      columns,

      // Computed
      items,

      // Methods
      downloadIncomingInvoice,
      getPreTaxAmounts,
      getTotalAmounts,
      downloadItem,
      getIncomingInvoiceIconType,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    // getRoute(item) {
    //   if (item.type == "charge") {
    //
    //   } else if (item.type == "expenseAccount") {
    //
    //   } else if (item.type == "invoice") {
    //
    //   }
    //   return this.$router.resolve({ name: 'Order view', params: { orderId: item.entity.id } }).href
    // }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>