<template>
  <!--  <div style="border: 1px solid blueviolet">-->
  <div>

    <div class="row mb-2">
      <!--      Left column-->
      <div
          v-if="!readOnly"
          class="col-md-4"
      >
        <h2 class="content-header-title border-right-0 pr-1 my-1">
          {{ title != '' ? capitalize($t(title)) : capitalize($t($route.name)) }}
        </h2>

        <button-add
            @click="$emit('addItem')"
            v-if="allowAdd"
            :withIcon="true"
            :isLoading="isAddButtonsLoading"
        />

        <button-upload
            @click="$emit('addItemByUpload')"
            v-if="allowAddByUpload"
            :withText="false"
            :withIcon="true"
            class="ml-2 px-1 mt-0 btnUpload"
            :isLoading="isAddButtonsLoading"
        />
      </div>

      <!--      Right column-->
      <div :class="'col-md-'+(readOnly?'12':'8')">
        <div class="row">
          <div class="col-12 d-flex justify-content-between mb-50 mb-md-0 mt-50 mt-md-0">

            <!--              Results number-->
            <div class="my-auto text-nowrap">
              <slot name="resultInfos"
                    :items="localItems"
                    :isLoading="isLoading">
                <icon
                    v-if="isLoading"
                    icon="spinner"
                    :spin="true"
                    class="text-warning"
                />

                {{ localItems.length }} {{ $tc('resultFound', localItems.length) }}
              </slot>
            </div>

            <!--              Filters-->
            <div class="w-100 ml-1 my-auto">
              <b-dropdown
                  variant="outline-primary"
                  class="dropdown-icon-wrapper my-auto"
                  size="sm"
              >
                <template #button-content>
                  <span class="mr-1">{{ capitalize($tc('filter', 2)) }} ({{ selectedFilters.length }})</span>
                  <icon icon="filter"/>
                </template>

                <div
                    v-for="(item,index) in localFilters"
                    :key="index"
                >
                  <b-dropdown-divider v-if="item.divider"/>

                  <b-dropdown-item
                      v-else
                      @mouseover.native="toogleItem(item, false)"
                      @mouseout.native="toogleItem(item, true)"
                      @click.native="selectFilter(item)"
                  >
                    <span>{{ item.text }}
                      <icon :icon="item.showSubMenu?'caret-down':'caret-left'"
                            v-if="item.children"/>
                    </span>
                    <div v-if="item.children"
                         class="sub-menu"
                         v-show="item.showSubMenu">

                      <div v-for="(item2,index2) in item.children"
                           :key="index2">
                        <b-dropdown-divider v-if="item2.divider"/>

                        <b-dropdown-form
                            v-else-if="item2.key == '_date_custom_filter'"
                            class="py-1">

                          <field-date
                              @click.native.stop.prevent
                              @click.native="item.showSubMenu=true"
                              :model.sync="localFilters[index].children[index2].value.start"
                              :name="$t('from:')"
                          />
                          <field-date
                              @click.native.stop.prevent
                              @click.native="item.showSubMenu=true"
                              :model.sync="localFilters[index].children[index2].value.end"
                              :name="$t('to:')"
                          />

                          <b-button
                              variant="primary"
                              size="sm"
                              @click="selectFilter(item2)"
                          >
                            {{ $t('validate') }}
                          </b-button>
                        </b-dropdown-form>

                        <b-dropdown-item
                            v-else
                            @click.native="selectFilter(item2)">
                          {{ item2.text }}
                        </b-dropdown-item>
                      </div>


                    </div>
                  </b-dropdown-item>
                </div>

              </b-dropdown>
            </div>


            <!--Number per pages-->
            <b-dropdown
                class="my-auto d-none d-md-block"
                v-show="selectedView != 'map'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="itemPerPagesDisplayed"
                variant="flat-primary"
                style="height: 37.77px;"
            >
              <b-dropdown-item @click="perPage=50">
                50
              </b-dropdown-item>
              <b-dropdown-item @click="perPage=100">
                100
              </b-dropdown-item>
              <b-dropdown-item @click="perPage=150">
                150
              </b-dropdown-item>
              <b-dropdown-item @click="perPage=200">
                200
              </b-dropdown-item>
            </b-dropdown>

            <!-- Buttons views  -->
            <b-form-radio-group
                v-if="!readOnly"
                v-model="selectedView"
                class="ml-1 list item-view-radio-group my-auto"
                buttons
                size="sm"
                button-variant="outline-primary"
            >
              <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
              >
                <icon
                    :icon="option.icon"
                />
              </b-form-radio>
            </b-form-radio-group>


          </div>
          <div class="col-12">
            <div class="d-flex justify-content-between">

              <!--            Filter badges-->
              <div class="d-flex"
                   :class="{'mr-1': selectedFilters.length!=0}">
                <b-badge
                    v-for="(item,index) in selectedFilters"
                    variant="light-primary"
                    class="cursor-pointer my-auto"
                    style="border-radius: 3px; font-size: 0.9rem; margin: 5px 2px 2px 5px;"
                    @click="selectedFilters.splice(index,1)"
                >
                  {{ item.text }}
                  <icon icon="times"/>
                </b-badge>
              </div>

              <!--            SearchBar-->
              <b-input-group class="input-group-merge">
                <b-form-input
                    v-model="query"
                    :placeholder="$t('Search')"
                    class="search-product"
                />
                <b-input-group-append is-text>
                  <icon icon="search"
                        class="text-muted"/>
                </b-input-group-append>
              </b-input-group>

            </div>

          </div>
        </div>

      </div>
    </div>

    <slot name="sectionsTop"
          class="mb-2"/>

    <!--    Grid view-->
    <section v-if="selectedView == 'grid'">
      <b-row>
        <b-col
            cols="12"
            md="3"
            v-for="(item, index) in paginatedItems"
            :key="index"
        >
          <slot
              name="gridView"
              :item="item"
          />
        </b-col>

      </b-row>
    </section>

    <!--    List view-->
    <section v-if="selectedView == 'list'">
      <b-row>
        <b-col cols="12">

          <b-card no-body>
            <b-table
                responsive
                :items="paginatedItems"
                :fields="tableColumnsLocal"
                :tbody-tr-class="rowClass"
                :no-local-sorting="true"
                @sort-changed="tableSortCompare"
                striped
                hover
                @row-clicked="$emit('columnClick', $event)"
            >

              <!--              Column head-->
              <template
                  v-for="(tableColumn, index) in columns"
                  v-slot:[`head(${tableColumn.key})`]="data">

                <slot
                    :name="'listView_head_'+tableColumn.key"
                    :item="data.field.display"
                >
                  {{ data.field.display }}
                </slot>

              </template>

              <!--              Cell-->
              <template
                  v-for="(tableColumn, index) in columns"
                  v-slot:[`cell(${tableColumn.key})`]="data">

                <div
                    :class="{'text-truncate': tableColumn.maxWidth?true:false}"
                    :style="tableColumn.maxWidth?{maxWidth: tableColumn.maxWidth}:{}"
                >

                  <slot
                      :name="'listView_cell_'+tableColumn.key"
                      :item="data.item"

                  >
                    <!--                    Amounts-->
                    <span
                        v-if="['preTaxAmount', 'vat', 'totalAmount', '_sumOwed'].includes(tableColumn.key)"
                        class="text-nowrap"
                    >
                      {{ currency(parseFloat(data.item[tableColumn.key])) }}
                    </span>

                    <!--                    Display objects-->
                    <span
                        v-else-if="isObject(data.item[tableColumn.key]) && ('_display' in data.item[tableColumn.key])"
                        class="text-nowrap"
                    >
                      {{ data.item[tableColumn.key]._display }}
                    </span>

                    <!--                    Months-->
                    <span v-else-if="tableColumn.key == 'month'">
                      {{ data.item[tableColumn.key]|moment('MMMM YYYY')|capitalize }}
                    </span>

                    <!--                    Dates-->
                    <span v-else-if="
                    $moment(data.item[tableColumn.key]).isValid() &&
                    isNaN(data.item[tableColumn.key]) &&
                    !Array.isArray(data.item[tableColumn.key])">
                      {{ data.item[tableColumn.key]|moment('L') }}
                    </span>

                    <span v-else>{{ data.item[tableColumn.key] }}</span>
                  </slot>
                </div>
              </template>


              <!--              Actions-->
              <template #cell(actions)="data">
                <slot
                    name="listView_cell_actions"
                    :item="data.item"
                >
                </slot>
              </template>

            </b-table>
          </b-card>

        </b-col>
      </b-row>
    </section>

    <!--    Map view-->
    <section v-if="selectedView == 'map'">
      <map-view ref="mapView"></map-view>
    </section>

    <!-- Pagination -->
    <section
        v-show="selectedView != 'map'"
        class="mt-1">
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="pageNumber"
              :total-rows="localItems.length"
              :per-page="perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <icon icon="chevron-left"/>
            </template>
            <template #next-text>
              <icon icon="chevron-right"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import ButtonAdd from '../button/Add'
import ButtonUpload from '../button/Upload'
import Ripple from 'vue-ripple-directive'
import mixinSearchFilter from './mixinSearchFilter'
import MapView from '../map/Map'

export default {
  directives: {
    Ripple,
  },
  components: {
    ButtonAdd,
    ButtonUpload,
    MapView,
  },
  mixins: [mixinSearchFilter],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    sortOptions: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    showPastYearsFilter: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    isActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    itemView: {
      type: String,
      default: 'grid'
    },
    addMapView: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>