<template>
  <b-button
      class="customButton"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      :variant="variant"
      :size="size"
      @click="$emit('click')"
      :style="title==''?(size=='sm'?'padding: 0.3rem 0.5rem;':'padding: 0.5rem; margin-top:3px'):''"
      :block="block"
      :disabled="isLoading||isDisabled"
      :class="{'cursor-not-allowed': isDisabled}"
  >

    <div class="d-flex justify-content-center">
      <b-spinner v-if="isLoading" small class="mr-50" />

      <icon v-if="withIcon && icon != ''"
            :icon="icon"
            :library="iconLibrary"
            :class="{'mr-50':title != ''}"/>

      <slot name="title">
        <span v-if="title != ''">{{ capitalize($t(title)) }}</span>
      </slot>
    </div>
  </b-button>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: ''
    },
    withIcon: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    iconLibrary: {
      type: String,
      default: 'fas'
    },
    size: {
      type: String,
      default: 'md'
    },
    block: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>